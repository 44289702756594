import React, { useState, useEffect, useMemo } from "react";
import styles from "./DetailsEdit.module.scss";
import { connect } from "react-redux";
import { deleteImageFromPortfolio, openMainModal, closeMainModal, deletePortfolio } from "../../../store/actions";
import { ReactComponent as XIcon } from "../../../assets/svg/x.svg";
import { ReactComponent as TrashIcon } from "../../../assets/svg/trashIcon.svg";
import { ReactComponent as DateIcon } from "../../../assets/svg/locationIcon.svg";
import { ReactComponent as NameIcon } from "../../../assets/svg/dateIcon.svg";
import { ReactComponent as DescriptionIcon } from "../../../assets/svg/description-pencil.svg";
import { ReactComponent as Plus } from "../../../assets/svg/plus-icon.svg"
import { ReactComponent as IMGIcon } from "../../../assets/svg/input-img.svg";
import { ReactComponent as LocationIcon } from "../../../assets/svg/descriptionIcon.svg";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


const DetailsEdit = ({
  values,
  formik,
  imageFile = [],
  fileName = [],
  isPortfolio,
  newImage,
  isProject,
  imageEdit,
  onImageFilesChange,
  isCreatingNewPortfolio,

  setImageEdit,
  ...props
}) => {


  let { nameEdit, descriptionEdit, endDateEdit, startDateEdit, zoneEdit } =
    values;

  const [isStartDateInput, setIsStartDateInput] = useState(false);
  const [isEndDateInput, setIsEndDateInput] = useState(false);
  const [imageFileEdit, setImageFile] = useState(imageFile);
  const [fileNameEdit, setFileName] = useState(fileName);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isEmpty, setIsEmpty] = useState(true);
  const [deletedImageIds, setDeletedImageIds] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);

  //Definimos la función que elimina las imágenes
  const deleteImage = (imageId) => {
    openMainModal({
      title: "¿Estás seguro que deseas eliminar esta imagen?",
      body: "Esta acción no se puede deshacer.",
      confirmHandler: () => {
        deleteImageFromPortfolio(imageId);
        closeMainModal();
      },
    });
    setDeletedImageIds((prevDeletedImageIds) => [...prevDeletedImageIds, imageId]);
  };

  //Maneja el estado del array de imagenes a mostrar
  useEffect(() => {
    if (imageEdit && imageEdit.length > 0) {
      setIsEmpty(false);
    } else {
      setIsEmpty(true);
    }
  }, [imageEdit]);

  //Maneja la eliminacion de imagenes del array

  const handleImageDelete = (index) => {
    const imageUrlsCopy = [...imageUrls];
    const imageEditCopy = [...imageEdit];

    imageUrlsCopy.splice(index, 1);
    imageEditCopy.splice(index, 1);

    setImageUrls(imageUrlsCopy);
    setImageEdit(imageEditCopy);

    // Verificar si el array imageEditCopy quedó vacío
    if (imageEditCopy.length === 0) {
        // Actualizar directamente el estado de Formik
        formik.setFieldValue("imageEdit", []);
    }
};


  //Definimos de donte tomamos las imagenes
  const imagenes = useMemo(() => {
    let imagenes = [];

    if (isPortfolio && isCreatingNewPortfolio && Array.isArray(imageEdit)) {
      imagenes = imageUrls.map((imageUrl, index) => {
        return (
          <div key={index} className={styles.infoGeneralContainer_customImg}>
            <img src={imageUrl} alt="" className={styles.infoGeneralContainer_customImg_img} />
          </div>
        );
      });
    } 

    return imagenes;
  }, [isPortfolio, deletedImageIds, deleteImage, isCreatingNewPortfolio, imageEdit]);


  const formatDate = (dateString) => {
    if (dateString && typeof dateString === "string") {
        const [day, month, year] = dateString.split('/');
        const fecha = new Date(`${year}-${month}-${day}`);
        return fecha.toLocaleDateString();
    }
    return "";
};

  const imageChangeHandler = (event) => {
    const files = event.target.files;
    const updatedFiles = Array.from(files);

    if (updatedFiles.length + imageFileEdit.length > 10) {
      openMainModal({
        title: "No se pueden agregar más de 10 imágenes",
        body: "Por favor, elimine alguna de las imágenes existentes ¡Muchas gracias!",
        isSuccess: true,
      });
      return;
    }
    // Actualiza los archivos de imagen
    formik.setFieldValue("imageEdit", [...formik.values.imageEdit, ...updatedFiles]);
    setImageFile((prevFiles) => [...prevFiles, ...updatedFiles]);
    setFileName((prevFileNames) => [
      ...prevFileNames,
      ...updatedFiles.map((file) => file.name),
    ]);
    onImageFilesChange((prevFiles) => [...prevFiles, ...updatedFiles]);

    // Lee el contenido de los archivos y genera las URLs de imagen
    updatedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const imageUrl = reader.result;
        setImageUrls((prevUrls) => [...prevUrls, imageUrl]);
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <div className={styles.infoGeneralContainer}>
      <Carousel
        className={styles.infoGeneralContainer_carousel}
        selectedItem={currentImageIndex}
        showThumbs={false}
        showStatus={false}
        onChange={(index) => setCurrentImageIndex(index)}
      >
        {imagenes}
      </Carousel>
      {isEmpty && (
        <div className={styles.emptyContainer}>
          <label
            htmlFor="imageForPortfolio"
            className={styles.itemInfo_imageMask}
          ></label>
          <span className={styles.emptyContainer_text}>No hay imágenes seleccionadas</span>
          <span className={styles.emptyContainer_text}>Click para agregar</span>
          <span className={styles.emptyContainer_text}><Plus height={15} width={15}/></span>
          <input
            type="file"
            name="imageForPortfolio"
            id="imageForPortfolio"
            onChange={imageChangeHandler}
            style={{ display: "none" }}
            accept="image/*"
            multiple
          />
        </div>

      )}
      {!isEmpty && (
      <div className={styles.itemInfo_containerThumb}>
        {imageEdit && imageEdit.length > 0 ? (
          imageEdit.map((file, index) => (
            <>
              <div key={index} className={styles.itemInfo_containerThumb_imageContainer}>
                <span className={styles.itemInfo_imageCount}>{index + 1}</span>
                <img src={imageUrls[index]} alt="" className={styles.itemInfo_image} />
                <button onClick={() => handleImageDelete(index)} className={styles.deleteThumbImg}>X</button>
              </div>
            </>
          ))
        ) : null}
        {!isEmpty && imageEdit && imageEdit.length < 10 && (
          <div className={styles.itemInfo_containerThumb_imageContainer_addImage}>
            <label
              htmlFor="imageForPortfolio"
              className={styles.itemInfo_imageMask}
            ></label>
            <input
              type="file"
              name="imageForPortfolio"
              id="imageForPortfolio"
              onChange={imageChangeHandler}
              style={{ display: "none" }}
              accept="image/*"
              multiple
            />
             Agregar más
            <Plus width={15} height={15}/>    
          </div>
        )}
      </div>
      )}
      <div className={styles.isPortfolio}>
        <div className={styles.itemInfo_header1}>
          <h3 className={styles.itemInfo_header1_text1}>Nombre del producto - servicio</h3>
          <div className={styles.itemInfo}>
            <NameIcon width={15} height={15} />
            <input
              type="text"
              id="nameEdit"
              value={nameEdit}
              className={styles.itemInfo_input}
              placeholder="Escriba un nombre"
              onChange={formik.handleChange}
            />
          </div>
        </div>
        <div className={styles.itemInfo_header2}>
          <h3 className={styles.itemInfo_header2_text1}>¿Dónde se realizó el trabajo?</h3>
          <div className={styles.itemInfo}>
            <LocationIcon
              width="11"
              height="15"
            />
            <input
              type="text"
              id="zoneEdit"
              value={zoneEdit}
              className={styles.itemInfo_input}
              placeholder="Ubicación"
              onChange={formik.handleChange}
            />
          </div>
        </div>
        <div className={styles.itemInfo_header2}>
          <h3 className={styles.itemInfo_header2_text1}>Fecha de inicio</h3>
          <div className={styles.itemInfo}>
            <DateIcon width="15" height="14" />
            {!isStartDateInput ? (
            <input
              type="date"
              name="startDateEdit"
              id="startDateEdit"
              className={`${styles.itemInfo_input}`}
              value={startDateEdit}
              onChange={(e) => {
                formik.handleChange(e);
                setIsStartDateInput(!isStartDateInput);
              }}
            /> ) : (
              <input
              type="text"
              name="startDateEdit"
              id="startDateEdit"
              className={`${styles.itemInfo_input}`}
              value={formatDate(startDateEdit)}
              onClick={() => setIsStartDateInput(false)}
            />
            )}           
          </div>
        </div>
        <div className={styles.itemInfo_header2}>
          <h3 className={styles.itemInfo_header2_text1}>Fecha de finalización</h3>
          <div className={`${styles.itemInfo}`}>
            <DateIcon width="15" height="14" />
            {!isEndDateInput ? (
            <input
              type="date"
              name="endDateEdit"
              id="endDateEdit"
              className={`${styles.itemInfo_input}`}
              value={endDateEdit}
              onChange={(e) => {
                formik.handleChange(e);
                setIsEndDateInput(!isEndDateInput);
              }}
            />
            ) : (
              <input
              type="text"
              name="endDateEdit"
              id="endDateEdit"
              className={`${styles.itemInfo_input}`}
              value={formatDate(endDateEdit)}
              onClick={() => setIsEndDateInput(false)}
            />
            )}
          </div>
        </div>
        <div className={styles.itemInfo_header3}>
          <h3 className={styles.itemInfo_header3_text}>Agregar una descripción detallada del producto - servicio, tareas realizadas, etc.</h3>
        </div>
        <div className={styles.itemInfo}>
          <DescriptionIcon
            width="13"
            height="13"
            style={{ marginBottom: "auto", marginTop: "12px" }}
          />
          <textarea
            value={descriptionEdit}
            id="descriptionEdit"
            name="descriptionEdit"
            className={`${styles.itemInfo_input} ${styles.itemInfo_area}`}
            onChange={formik.handleChange}
            placeholder="Ej: Instalación de 10 unidades de A/A en edificio de oficinas."
          />
        </div>
      </div>
    </div>



  );
};

const mapStateToProps = (state) => {
  return {
  }

}

export default connect(mapStateToProps, {
  deleteImageFromPortfolio,
  deletePortfolio,
  openMainModal,
  closeMainModal
})(DetailsEdit);