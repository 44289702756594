import React, { useEffect, useState, useCallback } from "react";
import styles from "./Feed.module.scss";
import { connect } from "react-redux";
import { openFeedTextModal, closeMainModal, getPosteos, getOwnPosteos, resetRecentPost } from "../../../store/actions";
import usePremiumCheck from "../../../hooks/usePremiumCheck";
import FeedCard from "./FeedCard";
import { ReactComponent as Foto } from "../../../assets/svg/fotoAdd.svg";
import { ReactComponent as Video } from "../../../assets/svg/addVideo.svg";
import { ReactComponent as ArrowDown } from "../../../assets/svg/arrowDownIcon.svg";
import { ReactComponent as ArrowUp } from "../../../assets/svg/arrowUpIcon.svg";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { useNavigate } from "react-router-dom";
import FeedCardSkeleton from "./FeedCardSkeleton";
import InfiniteScroll from 'react-infinite-scroller';


const Feed = ({
    userData,
    openFeedTextModal,
    closeMainModal,
    getPosteos,
    getOwnPosteos,
    ownPosteos,
    resetRecentPost,
    posteos,
    recentPost,
    ...props }) => {
    const [selectedOption, setSelectedOption] = useState("Ordenar");
    const [showMenu, setShowMenu] = useState(false);
    const [myPosts, setMyPosts] = useState(false);
    const [items, setItems] = useState([]);
    const [loadingMore, setLoadingMore] = useState(false);
    const [isSorted, setIsSorted] = useState(false);
    const [sortedItems, setSortedItems] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const premiumCheck = usePremiumCheck();
   const navigate = useNavigate();

    //Carga los posteos
    useEffect(() => {
        getPosteos("DESC", "DATE");
        resetRecentPost();
    }, []);

    useEffect(() => {
        if (selectedOption === "recomendados") {
            getPosteos("ASC", "MG");

        } else if (selectedOption === "recientes") {
            getPosteos("DESC", "DATE");
        }

    }, [selectedOption]);


    //Quita InfiniteScroll si se ordena por recomendados, propios o recientes
    useEffect(() => {
        if (isSorted) {
            setItems(posteos);
            setLoadingMore(false);
        }
    }, [posteos]);

    //Actualiza la lista una vez que el usuario crea un posteo
    useEffect(() => {
        if (recentPost?.user?.id == userData?.id) {
            getPosteos("DESC", "DATE");
            setSelectedOption("recientes");
            setIsSorted(true);
        }
    }, [recentPost]);

    const sizes = [
        { columns: 1, gutter: 10 },
        { mq: '768px', columns: 2, gutter: 10 },
        { mq: '1024px', columns: 3, gutter: 10 }
    ];

    const shufflePost = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const fetchItems = useCallback(async () => {
        let isMounted = true;

        if (loadingMore || !isMounted) {
            return;
        }

        setLoadingMore(true);

        try {
            const newItems = myPosts ? ownPosteos : posteos;

            const shuffledItems = !isSorted ? shufflePost(newItems) : newItems;

            if (!isMounted) {
                return;
            }

            setItems((prevItems) => (page === 1 ? shuffledItems : [...prevItems, ...shuffledItems]));

            await new Promise((resolve) => setTimeout(resolve, 3000));

            if (!isMounted) {
                return;
            }

            if (newItems.length === 0) {
                setHasMore(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            if (isMounted) {
                setLoadingMore(false);
                setPage((prevPage) => prevPage + 1);
            }
        }
    }, [page, myPosts, ownPosteos, posteos, loadingMore]);

    //Abre el modal de texto
    const handleOpenMainModalTxt = () => {
        openFeedTextModal({
            text: true,
        });
    };

    //Abre el modal de imagen
    const handleOpenMainModalWithImg = () => {
        openFeedTextModal({
            image: true,
        });
    }

    //Abre el modal de video
    const handleOpenMainModalWithVideo = () => {
        openFeedTextModal({
            video: true,
        });
    };

    //Menu de opciones de visualizacion
    const handleOptionClick = (option) => {
        setSelectedOption(option);

        if (option === "recientes") {
            setMyPosts(false);
            setIsSorted(true)
        }
        if (option === "recomendados") {
            setMyPosts(false);
            setLoadingMore(true);
            setIsSorted(true);

        }
        if (option === "propios") {
            getOwnPosteos(userData.id);
            setMyPosts(true);
            setIsSorted(false);
            setItems(ownPosteos);
        }
        setShowMenu(false);
    };

    const userFullName = userData.type === "COMPANY" ? userData.razon_social : userData.name + " " + userData.last_name;

    const handleRedirectToProfile = () => {
        navigate(`/comunidad-edify/${userData.id}/${userFullName}`)
    }

    const getButtonLabel = (option) => {
        switch (option) {
            case "recientes":
                return "Recientes";
            case "propios":
                return "Propios";
            case "recomendados":
                return "Recomendados";
            default:
                return "Ordenar";
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.feed}>
                <div className={styles.feed_header}>
                    <div className={styles.feed_itemHeader}>
                        <div className={styles.feed_input} onClick={premiumCheck(handleOpenMainModalTxt)}>
                            <input type="text"
                                readOnly
                                placeholder={"¿Qué querés compartir en edify?"}
                                className={styles.feed_input_item} />
                            <div className={styles.feed_itemFooterContainer} >
                                <div className={styles.feed_itemFooterLeft} onClick={premiumCheck(handleOpenMainModalWithImg)}>
                                    <Foto width={20} height={20} />
                                    <input type="file" name="file" id="file" className={styles.feed_itemFooterLeft_input} />
                                </div>
                                <div className={styles.feed_itemFooterRight} onClick={premiumCheck(handleOpenMainModalWithVideo)}>
                                    <Video width={20} height={20} />
                                    <input type="file" name="file" id="file" className={styles.feed_itemFooterRight_input} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <header className={styles.header}>
                        <div className={styles.header_titleSortContainer}>
                            <div className={styles.dropdown}>

                                <button className={styles.dropdown_button} onClick={() => setShowMenu(!showMenu)}>
                                    {showMenu ? <ArrowUp width={10} height={10} title="Ordenar" /> : <ArrowDown width={10} height={10} title="Ordenar" />}
                                    {getButtonLabel(selectedOption)}
                                </button>
                                {showMenu && (
                                    <>
                                        <div className={styles.dropdown_content}>
                                            <button
                                                onClick={() => handleOptionClick("recientes")}
                                                className={styles.dropdown_content_option}
                                            >
                                                Recientes
                                            </button>
                                            <button
                                                onClick={() => handleOptionClick("propios")}
                                                className={styles.dropdown_content_option}
                                            >
                                                Propios
                                            </button>
                                            <button
                                                onClick={() => handleOptionClick("recomendados")}
                                                className={styles.dropdown_content_option}
                                            >
                                                Recomendados
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </header>
                </div>
            </div>
            <div className={styles.feedItemContainer}>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={isSorted ? () => { } : fetchItems}
                    hasMore={true || false}
                    sizes={sizes}
                >
                    <ResponsiveMasonry
                        columnsCountBreakPoints={{ 400: 1, 800: 2, 1400: 3 }}
                    >
                        <Masonry gutter="10px">

                            {items.map((item, index) => (
                                <FeedCard
                                    key={`${item.id}-${page}-${index}`}
                                    item={item}
                                    myPosts={myPosts}
                                />
                            ))}
                            {loadingMore && Array.from({ length: 30 }).map((_, index) => (
                                <FeedCardSkeleton key={`skeleton-${index}`} />
                            ))}
                        </Masonry>
                    </ResponsiveMasonry>
                </InfiniteScroll>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userData: state.general.userData,
        posteos: state.dashboard.posteos,
        ownPosteos: state.dashboard.ownPosteos,
        recentPost: state.dashboard.recentPost,
    };
};

export default connect(mapStateToProps, { openFeedTextModal, closeMainModal, getPosteos, getOwnPosteos, resetRecentPost })(Feed);
