import React, { useEffect } from "react";
import styles from "./WorkApplyModal.module.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { closeMainModal, openMainModal, getAllWorkBagUsers } from "../../store/actions";
import { useNavigate } from "react-router-dom";

const WorkApplyModal = ({ workBagUsers, setShowApplyModal, openMainModal, closeMainModal, setShowForm, getAllWorkBagUsers, workOffer }) => {

    const navigate = useNavigate();

    useEffect(() => {
        if (workBagUsers?.length === 0) {
            getAllWorkBagUsers();
        }
    }, []);

     const handleSubmit = (values) => {
        const isUserInWorkBag = workBagUsers.find((user) => user.email === values.email)
        if (!isUserInWorkBag) {
            setShowApplyModal(false);
            openMainModal({
                title: "¡Ups!",
                body: "Parece que no estás en nuestra base de datos. Por favor, registrate para poder aplicar a esta oferta",
                isSuccess: true,
                customCloseMainModal: () => {
                    setShowForm(true);
                    closeMainModal();                  
                }
            })
        } else {
            setShowApplyModal(false);
            closeMainModal();
            navigate(`/bolsadetrabajo/${workOffer.id}`)
        }
    }

    const initialValues = {
        email: ""
    }
   
    const formSchema = {
        email: Yup.string().email("Email inválido").required("Campo requerido")
    }

    const formik = useFormik({
        initialValues,
        validateOnMount: true,
        validationSchema: Yup.object(formSchema),
        onSubmit: (values) => {
            handleSubmit(values)
        }

    })

    return (
        <div className={styles.overlay}>
            <div className={styles.modal}>
                <header className={styles.header}>
                    <h2 className={styles.header_title}>¿Te interesa esta oferta?</h2>
                <button className={styles.header_button} onClick={() => setShowApplyModal(false)}>X</button>
                </header>
                <main className={styles.main}>
                    <h4>Dejanos tu email y te contactaremos</h4>
                    <form className={styles.form} onSubmit={formik.handleSubmit}>
                        <input className={styles.form_input} 
                            type="email" 
                            placeholder="ejemplo@mail.com"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange} />
                        <button type="submit" className={styles.form_button} disabled={!formik.isValid}>Enviar</button>
                    </form>
                </main>
            </div>  
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        workBagUsers: state.register.workBagUsers
    };
}

export default connect(mapStateToProps, { openMainModal, closeMainModal, getAllWorkBagUsers })(WorkApplyModal);