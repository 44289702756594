import React, { useState } from "react";
import styles from "./ContactSolicitud.module.scss";
import { ReactComponent as ContactMessageIcon } from "../../../assets/svg/contactMessageIconRight.svg";
import CheckedProfile from "../../../assets/images/perfilChecked.png";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { setViewNotification, deleteNotification } from "../../../store/actions";
import LoadingDots from "../../UI/Loading/LoadingDots";
import { ReactComponent as WhatsAppIcon } from "../../../assets/svg/whatsAppIcon.svg";
import { ReactComponent as EmailIcon } from "../../../assets/svg/gmailIcon.svg";
import { ReactComponent as TrashIcon } from "../../../assets/svg/trashIcon.svg";

const ContactSolicitud = ({ contact, setViewNotification, deleteNotification }) => {
    const [isLoading, setIsLoading] = useState(false);
   const navigate = useNavigate();
    const { message, view, createdAt } = contact;
    const { name, last_name, razon_social, validated, image_profile, type, id, phone, username } = contact?.userSender;

    const userSenderFullName = type === "COMPANY" ? razon_social : name + " " + last_name;

    const handleRedirectToUser = () => {
        navigate(`/comunidad-edify/${id}/${userSenderFullName}`);
    }

    const handleSetViewNotification = () => {
        setIsLoading(true);
        setViewNotification(contact?.id);
        setTimeout(() => {
            setIsLoading(false);
        }, 4000);
    }

    const handleDeleteNotification = () => {
        deleteNotification(parseInt(contact?.id));
    }

    const formatTimeFromNow = (time) => {
        const date = new Date(time);
        const now = new Date();
        const diff = now - date;
        const seconds = Math.floor(diff / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);
        const years = Math.floor(months / 12);

        if (seconds < 60) {
            return `${seconds === 1 ? '1 segundo' : `${seconds} segundos`}`;
        } else if (minutes < 60) {
            return `${minutes === 1 ? '1 minuto' : `${minutes} minutos`}`;
        } else if (hours < 24) {
            return `${hours === 1 ? '1 hora' : `${hours} horas`}`;
        } else if (days < 30) {
            return `${days === 1 ? '1 día' : `${days} días`}`;
        } else if (months < 12) {
            return `${months === 1 ? '1 mes' : `${months} meses`}`;
        } else {
            return `${years === 1 ? '1 año' : `${years} años`}`;
        }
    }

    const handleWhatsAppClick = () => {
        // Generar la URL de WhatsApp
        const messageToSend = "Hola! Recibí un mensaje tuyo en Edify y con gusto podemos agendar una reunión. ¿Cuándo te parece?";
        const whatsAppURL = `https://api.whatsapp.com/send?phone=${encodeURIComponent(phone)}&text=${encodeURIComponent(messageToSend)}`;
        // Abrir la ventana de WhatsApp en una nueva pestaña
        window.open(whatsAppURL, '_blank');
    }

    const handleEmailClick = () => {
        // Generar la URL de correo electrónico
        const emailSubject = encodeURIComponent("Re: Contacto desde edify.la");
        const emailBody = encodeURIComponent("Hola! Recibí un mensaje tuyo en Edify y con gusto podemos agendar una reunión. ¿Cuándo te parece?");
        const emailURL = `mailto:${encodeURIComponent(username)}?subject=${emailSubject}&body=${emailBody}`;
        // Abrir la ventana de correo electrónico en una nueva pestaña
        window.open(emailURL, '_blank');
    }

    return (
        <div className={styles.container}>
            <header className={styles.container_header}>
                <div className={styles.container_header_title}>
                <ContactMessageIcon width={20} height={20} />
                <div className={styles.container_header_title_txt}>
                    Un usuario quiere contactarte
                </div>
                </div>
                <div className={styles.container_buttons} title="Eliminar notificación">                  
                    {view === null && !isLoading &&
                        <div className={styles.container_isViewed} title="Marcar como vista" onClick={() => handleSetViewNotification()}>
                            <div className={styles.container_isViewed_checked}></div>
                        </div>
                    }
                    {isLoading && <div className={styles.container_isViewed}> <LoadingDots blueDots /> </div>}
                    <div className={styles.container_buttons_trash} onClick={() => handleDeleteNotification()}>
                        <TrashIcon width={20} height={20} />
                    </div>
                </div>

            </header>
            <div className={styles.container_body}>
                <div className={styles.container_body_image}>
                    <img className={styles.container_body_image_img} src={image_profile} alt="profile" />
                    {validated && <img className={styles.container_body_image_checked} src={CheckedProfile} alt="checked" />}
                </div>
                <div className={styles.container_body_message}>
                    <p className={styles.container_body_message_subTitle} onClick={() => handleRedirectToUser()}>{userSenderFullName} escribió:</p>
                    <p className={styles.container_body_message_txt}>{message}</p>
                    <div className={styles.container_body_message_buttons}>
                        <p>Hace &nbsp;{formatTimeFromNow(createdAt)}</p>
                        {phone && <p onClick={handleWhatsAppClick} className={styles.container_body_message_buttons_icon}>&nbsp;-&nbsp;<WhatsAppIcon width={15} height={20} title="Enviar WhatsApp" /></p>}
                        <p onClick={handleEmailClick} className={styles.container_body_message_buttons_icon}>&nbsp;-&nbsp;<EmailIcon width={20} height={20} title="Enviar mail" /></p>
                    </div>
                </div>
            </div>

        </div>
    );
};

const mapStateToProps = (state) => {
    return {

    };
}

export default connect(mapStateToProps, { setViewNotification, deleteNotification })(ContactSolicitud);