import React from "react";
import styles from "./QuotationsItems.module.scss";
import { connect } from "react-redux";
import {
  setPostulationProjectId,
  openNDAModal,
  closeNDAModal,
  setResponsiveFall,
  goToProjectId,
} from "../../store/actions/index";
import QuotationItem from "./quotationItem";
import ProjectsItem from "../Dashboard/project/ProjectsItem";

const QuotationsItem = ({
  dashboardView,
  onFocusRef,
  refProjectId,
  typeViewer,
  item,
  userData,
  setModalOpen,
  isEditing,
  setIsEditing,
  idForEdit,
  setIdForEdit,
  showSubprojects = false,
  ...props
}) => {

  const handleOnClick = () => {
    const nombre = item?.name?.replace(/ /g, "-");

    window.open(
      `/materiales-a-licitar-publicos/${item.id}/${nombre}`,
      "_blank"
    );
  };

  return item.type === "PRIVATE" ? (
    <QuotationItem 
          item={item}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          idForEdit={idForEdit}
          setIdForEdit={setIdForEdit}/>
  ) : (
    <div className={styles.wapper}>
      <ProjectsItem handleOnClick={handleOnClick} item={item} isQuotation />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    dashboardView: ownProps.dashboardView || false,
    item: ownProps.item,
    profileVisualizer: state.general.profileVisualizer,
  };
};

export default
  connect(mapStateToProps, {
    setPostulationProjectId,
    openNDAModal,
    closeNDAModal,
    goToProjectId,
    setResponsiveFall,
  })(QuotationsItem);
