import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  endLoading,
  startLoading,
  closeMainModal,
  closeNDAModal,
  closeAll,
  openWorkOfferModal,
} from "./store/actions";
import "./App.scss";
import WorkBag from "./pages/WorkBag/WorkBag";
import MercadoDeTrabajo from "./components/MercadoDeTrabajo/MercadoDeTrabajo";
import MainModal from "./components/UI/MainModal/MainModal";
import FeedTextModal from "./components/UI/FeedModal/FeedTextModal";
import LoginPage from "./pages/LoginPage/LoginPage";
import RegisterPage from "./pages/RegisterPage/RegisterPage";
import WelcomeLayout from "./pages/WelcomeLayout/WelcomeLayout";
import Faq from "./pages/Faq/Faq";
import SelectType from "./pages/RegisterSteps/SelectType/SelectType";
import RegisterForm from "./pages/RegisterSteps/RegisterForm/RegisterForm";
import CongratsSuscription from "./pages/RegisterSteps/CongratsSuscription/CongratsSuscription";
import Resumen from "./pages/RegisterSteps/Resumen/Resumen";
import Dashboard from "./pages/Dashboard/Dashboard";
import ProyectosPrivados from "./components/Dashboard/PrivateProyects/PrivateProyects";
import RecuperarPassword from "./pages/RecuperarPassword/RecuperarPassword";
import MarketPlace from "./components/MarketPlace/MarketPlace";
import PublicQuotationById from "./components/MarketPlace/PublicQuotations/PublicQuotationById";
import PublicProjectById from "./components/Dashboard/project/PublicProjects/PublicProjectById";
import WorkOfferById from "./pages/WorkBag/WorkOfferById";
import Seguros from "./components/SegurosParaTrabajadores/Seguros";
import Alquiler from "./components/AlquilerDeMaquinarias/Alquiler";
import PostularmeNDA from "./pages/Postulaciones/PostularmeNDA/PostularmeNDA";
import Solicitudes from "./pages/Solicitudes/Solicitudes";
import Licitaciones from "./components/Dashboard/LicitacionesPublicas/LicitacionesPublicas";
import PresupuestosConfirm from "./pages/Solicitudes/PresupuestosConfirm/PresupuestosConfirm";
import PrivateRoute from "./utils/PrivateRoute";
import ResponsiveFall from "./components/UI/ResponsiveFall/ResponsiveFall";
import NewModal from "./components/UI/NewModal/NewModal";
import Vector from "./assets/svg/vectorEdify.svg";
import ForgotPasswordPage from "./pages/ForgotPasswordPage/ForgotPasswordPage";
import { AnimatePresence } from "framer-motion";
import MediaQuery from "react-responsive";
import RegisteredUserProfile from "./components/RegisteredUsers/RegisteredUserProfile";
import SuscriptionModal from "./components/UI/SuscriptionModal/SuscriptionModal";
import FreeTrialModal from "./components/UI/SuscriptionModal/FreeTrialModal";
import AdjudicatedProjectById from "./components/Dashboard/PublicProjectsAdjudicated/AdjudicatedProjectById";
import ComunidadEdify from "./components/ComunidadEdify/ComunidadEdify";
import ProyectoId from "./components/Dashboard/project/ProyectoId";
import QuotationId from "./components/MarketPlace/QuotationId";
import OfertaDeTrabajo from "./components/MercadoDeTrabajo/OfertaDeTrabajo";
import NotFound from "./pages/NotFound/NotFound";
import InvitePage from "./pages/InvitePage/InvitePage";
import GuestAccountModal from "./components/UI/GuestAccountModal/GuestAccountModal";
import SendContactModal from "./components/UI/ContactModal/SendContactModal";
import CalificationModal from "./components/UI/CalificationModal/CalificationModal";
import Tutorial from "./components/Tutorial/Tutorial";
import EdifySpinner from "./components/UI/Loading/SpinnerEdify";
import { initMercadoPago } from "@mercadopago/sdk-react";

// Initialize Mercado Pago before everything else
const MP_PUBLIC_KEY = "APP_USR-c4c98f5a-e0ae-4f85-bf12-61e264846b7f";
initMercadoPago(MP_PUBLIC_KEY, {
  locale: "es-AR",
});

const App = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleDOMContentLoaded = () => {
      props.startLoading();
    };
  
    const handleLoad = () => {
      props.endLoading();
    };
  
    if (document.readyState === 'loading') {
      window.addEventListener("DOMContentLoaded", handleDOMContentLoaded);
      window.addEventListener("load", handleLoad);
    } else {
      handleDOMContentLoaded();
      handleLoad();
    }
  
    return () => {
      window.removeEventListener("DOMContentLoaded", handleDOMContentLoaded);
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  useEffect(() => {
    props.closeMainModal();
  }, [props.closeMainModal]);

  useEffect(() => {
    document.body.style.overflow = props.newModalOpen ? "hidden" : "unset";
  }, [props.newModalOpen]);

  const checkPathIncludes = (route) => location.pathname.includes(route);


  const goBack = () => {
    props.closeAll();
    navigate("/");
  };

  let isDashboard = checkPathIncludes("dashboard");

  return (
    <div style={{ overflow: "hidden" }}>
      {props.responsiveFall && (
        <MediaQuery query={"(max-width: 900px)"}>
          <ResponsiveFall />
        </MediaQuery>
      )}
      <AnimatePresence exitBeforeEnter>
        <Routes>
          <Route path="/welcome" element={<WelcomeLayout typeLogo="welcome" />} />
          <Route path="/success" element={<WelcomeLayout typeLogo="success" />} />

          <Route
            path="/obra-publica-a-licitar/:id/:name"
            element={
              <PrivateRoute
                element={PublicProjectById}
                isPremiumContent={true}
                hasLayout={true}
              />
            }
          />
          <Route
            path="/comunidad-edify"
            element={
              <PrivateRoute
                element={ComunidadEdify}
                hasLayout={true}
              />
            }
          />
          <Route
            path="/comunidad-edify/:id/:name"
            element={
              <PrivateRoute
                element={RegisteredUserProfile}
                hasLayout={true}
              />
            }
          />
          <Route
            path="/materiales-a-licitar-publicos/:id/:name"
            element={
              <PrivateRoute
                element={PublicQuotationById}
                hasLayout={true}
              />
            }
          />
          <Route
            path="/licitacionespublicas/proyectos-adjudicados/:id/:name"
            element={
              <PrivateRoute
                element={AdjudicatedProjectById}
                hasLayout={true}
                isAdjudicated
              />
            }
          />
          <Route
            path="/faq"
            element={
              <PrivateRoute
                element={Faq}
                hasLayout={true}
              />
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute
                element={Dashboard}
                hasLayout={true}
                isDashboard={isDashboard}
              />
            }
          />
          <Route
            path="/proyectosprivados"
            element={
              <PrivateRoute
                element={ProyectosPrivados}
                hasLayout={true}
              />
            }
          />
          <Route
            path="/proyectosprivados/:id"
            element={
              <PrivateRoute
                element={ProyectoId}
                hasLayout={true}
              />
            }
          />
          <Route
            path="/licitacionespublicas"
            element={
              <PrivateRoute
                element={Licitaciones}
                hasLayout={true}
              />
            }
          />
          <Route
            path="/cotizaciondemateriales"
            element={
              <PrivateRoute
                element={MarketPlace}
                hasLayout={true}
              />
            }
          />
          <Route
            path="/cotizaciondemateriales/:id"
            element={
              <PrivateRoute
                element={QuotationId}
                hasLayout={true}
              />
            }
          />
          <Route
            path="/segurosparatrabajadoresyequipos"
            element={
              <PrivateRoute
                element={Seguros}
                hasLayout={true}
              />
            }
          />
          <Route
            path="/alquilerdemaquinarias"
            element={
              <PrivateRoute
                element={Alquiler}
                hasLayout={true}
              />
            }
          />
          <Route
            path="/postulaciones/postularme/confirm"
            element={
              <PrivateRoute
                element={PostularmeNDA}
                hasLayout={true}
              />
            }
          />
          <Route
            path="/solicitudes"
            element={
              <PrivateRoute
                element={Solicitudes}
                hasLayout={true}
              />
            }
          />
          <Route
            path="/mercadodetrabajo"
            element={
              <PrivateRoute
                element={MercadoDeTrabajo}
                hasLayout={true}
              />
            }
          />
          <Route
            path="/solicitudes/presupuesto"
            element={
              <PrivateRoute
                element={PresupuestosConfirm}
                hasLayout={true}
              />
            }
          />
          <Route
            path="/select-type"
            element={<SelectType />}
          />
          <Route
            path="/register-form"
            element={<RegisterForm />}
          />
          <Route
            path="/congratulations"
            element={<CongratsSuscription />}
          />
          <Route
            path="/resume"
            element={<Resumen />}
          />
          <Route
            path="/bolsadetrabajo"
            element={<WorkBag />}
          />
          <Route
            path="/bolsadetrabajo/:id"
            element={<WorkOfferById />}
          />
          <Route
            path="/register"
            element={<RegisterPage />}
          />
          <Route
            path="/forgotpassword"
            element={<ForgotPasswordPage />}
          />
          <Route
            path="/recovery/:token"
            element={<RecuperarPassword />}
          />
          <Route
            path="/invite"
            element={<InvitePage />}
          />
          <Route
            path="/"
            element={<LoginPage />}
          />
          <Route
            path="*"
            element={<NotFound />}
          />
        </Routes>
      </AnimatePresence>
      {props.openMainModal && <MainModal />}
      {props.openFeedTextModal && <FeedTextModal />}
      {props.openNDAModal && (
        <PostularmeNDA
          opened={props.openNDAModal}
          closeModal={props.closeNDAModal}
        />
      )}
      {props.newModalOpen && (
        <NewModal
          icon={Vector}
          title={"Error de verificación"}
          description={
            "El token ha expirado. Por favor, vuelva a enviar la verificación"
          }
          buttonSend={{
            title: "Volver a enviar",
            onClick: this.sendAgain,
          }}
          buttonBack={{
            title: "Volver al inicio",
            onClick: goBack,
          }}
        />
      )}
      {props.showSuscriptionModal &&
        (props.isFreeTrialAvailable ? (
          props.isGuestAccount ? (
            <GuestAccountModal />
          ) : (
            <FreeTrialModal />
          )
        ) : (
          <SuscriptionModal />
        ))}
      {props.showSendContactNotificationModal.isOpen && (
        <SendContactModal />
      )}
      {props.calificationModal.isOpen && <CalificationModal />}
      {props.workOfferModal && <OfertaDeTrabajo />}
      {props.tutorialModal && <Tutorial />}
      {props.edifySpinner && <EdifySpinner />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.general.userData,
    isAuth: state.general.isAuth,
    openMainModal: state.general.openMainModal,
    openFeedTextModal: state.general.openFeedTextModal,
    openPerfilVisualizerModal: state.general.recoverOpen,
    openNDAModal: state.general.openNDAModal,
    workOfferModal: state.general.workOfferModal,
    newModalOpen: state.general.newModalOpen,
    responsiveFall: state.general.responsiveFall,
    showSuscriptionModal: state.general.showSuscriptionModal,
    isFreeTrialAvailable: state.general.isFreeTrialAvailable,
    isGuestAccount: state.general.isGuestAccount,
    showSendContactNotificationModal: state.general.showSendContactNotificationModal,
    calificationModal: state.general.calificationModal,
    tutorialModal: state.dashboard.tutorialModal,
    edifySpinner: state.general.edifySpinner
  };
};

export default connect(mapStateToProps, {
  startLoading,
  endLoading,
  closeMainModal,
  closeNDAModal,
  closeAll,
})(App);

