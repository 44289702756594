import React, { useState, useEffect } from "react";
import styles from "./MainInfoItem.module.scss";
import { useFormik } from "formik";
import { connect } from "react-redux";
import defaultImage from "../../assets/images/foto-bienvenida.jpg";
import {
  getPortfolioQuestions,
  openMainModal,
  setOpenMainId,
  setEditProjectsShow,
  setProjectFormStyle,
  setProjectToEdit,
  displayInfoHandler,
  setSubProjectStyle,
} from "../../store/actions/profileActions";
import {
  updatePortfolio,
  deletePortfolio,
} from "../../store/actions/registerActions";
import DetailsInfo from "../UI/DetailsInfo/DetailsInfo";
import { fireAnalytics } from "../../utils/firebase";

const MainInfoItem = ({
  deletePortfolio,
  getPortfolioQuestions,
  setEditProjectsShow,
  isPostulating,
  userData,
  fromPage,
  defaultDropdownOpen,
  displayInfoHandler,
  isVisualizer,
  isPortfolio,
  isProject,
  setOpenMainId,
  openMainId,
  openMainModal,
  updatePortfolio,
  item,
  isPostulation,
  displayInfo,
  type,
  showEditProject,
  setProjectToEdit,
  setSubProjectStyle,
  setProjectFormStyle,
  setIsEditing,
  isEditing,
  id,
  myPerson,
  ...props
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(defaultDropdownOpen);
  const [imEditing, setImEditing] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [imageEdit, setImageEdit] = useState([]);
  const [buttonDiscard, setButtonDiscard] = useState(false);
  const [buttonUpdate, setButtonUpdate] = useState(false);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
  });

  let image;
  if (isPostulation) {
    image = item.proyecto?.proyecto_images[0]?.image;
  } else if (isProject) {
    image = item.proyecto_images[0]?.image;
  } else if (isPortfolio) {
    image = item.portfolio_images[0]?.image;
  }


  let i;
  if (type === "postulations") {
    i = item.proyecto;
  } else i = item;

  useEffect(() => {
    if (!defaultDropdownOpen && openMainId && item.id !== openMainId) {
      setDropdownOpen(false);
    }
    if (isEditing) fireAnalytics("continue_draft", { category: "publishing" });
  }, [item, openMainId, dropdownOpen]);

  useEffect(() => {
    if (isProject) {
      displayInfoHandler(true)
      setProjectFormStyle(false)
    } else if (isPostulation) {
      displayInfoHandler(true)
      setProjectFormStyle(true)
    }
  }, [dropdownOpen]);

  useEffect(() => {
    if (isPortfolio && !isVisualizer) {
      setInitialValues({
        nameEdit: item.name,
        descriptionEdit: item.description,
        startDateEdit: item.startDate,
        endDateEdit: item.endDate,
        zoneEdit: item.zone,
        imageEdit: image,
      });
    }
  }, [item]);

  useEffect(() => {
    if (isPortfolio && !isVisualizer) {
      let isFormChanged = formik.values !== initialValues;
      let isImageChanged = imageEdit !== null;
      if (isFormChanged || isImageChanged) {
        setButtonUpdate(true);
        setButtonDiscard(true);
      } else {
        setButtonUpdate(false);
        setButtonDiscard(false);
      }
    }
  }, [formik.values, imageEdit]);

  const showInfoHandler = (bool) => {
    setOpenMainId(item.id);

    setDropdownOpen(bool);
  };

  const handleClose = () => {
    setImEditing(!imEditing);
  };


  //Funcion de update del portfolio
  const editHandler = () => {
    if (isPortfolio && imEditing) {
      openMainModal({
        title: "¿Estas seguro que deseas guardar los cambios?",
        body: "Se actualizara el proyecto de tu portfolio y sera visible en tu perfil.",
        isSuccess: false,
        confirmHandler: () =>
          updatePortfolio(formik.values, imageEdit, item.id),
      });
      handleClose();
      return;
    } 
  };

  return (
    <div
      className={`${styles.container} ${isPostulation || isProject ? styles.isPostulation : ""
        }`}
    >
        <>
          <DetailsInfo
            perfilAjeno={props.perfilAjeno}
            isPostulation={isPostulation}
            isPostulating={isPostulating}
            isPortfolio={isPortfolio}
            isProject={isProject}
            dropdownOpen={true}
            setDropdownOpen={showInfoHandler}
            name={i.name}
            zone={i.zone}
            startDate={i.startDate}
            endDate={i.endDate}
            description={i.description}
            item={item}
            displayInfo={displayInfo}
            fromPage={fromPage}
            isVisualizer={isVisualizer}
            image={image === undefined ? defaultImage : image}
            handler={editHandler}
            myPerson={myPerson}
          />
        </>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    openMainId: state.profile.openMainId,
    displayInfo: ownProps.displayInfo || state.profile.displayInfo,
    showEditProject: state.profile.editProjectsShow,
    userData: state.general.userData,
  };
};
export default connect(mapStateToProps, {
  openMainModal,
  updatePortfolio,
  deletePortfolio,
  setEditProjectsShow,
  setProjectFormStyle,
  getPortfolioQuestions,
  setSubProjectStyle,

  setProjectToEdit,
  setOpenMainId,
  displayInfoHandler,
})(MainInfoItem);
