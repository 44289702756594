import React, { useEffect, useState } from "react";
import styles from "./LicitacionesPublicas.module.scss";
import Loading from "../../UI/Loading/Loading";
import { connect } from "react-redux";
import {
  clearProjects,
  setProjectFormStyle,
  setSubProjectStyle,
  resetProjectToEdit,
  filterPublicProjects,
  openFeedTextModal,
  openTutorialModal,
} from "../../../store/actions";
import FilterPublicProject from "../FilterBy/FilterPublicProjects/FilterPublicProject";
import EmptyState from "../../UI/EmptyState/EmptyState";
import Botonera from "../../Botonera/Botonera";
import useSuscriptionModalTimeout from "../../../hooks/useSuscriptionModalTimeout";
import Pagination from "../../../hooks/pagination";
import { ReactComponent as Faq } from "../../../assets/svg/FAQ.svg";


const LicitacionesPublicas = ({
  allProjects,
  loading,
  openTutorialModal,
}) => {
  const pageNumberLimit = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPageLimit, setMaxPageLimit] = useState(5);
  const [minPageLimit, setMinPageLimit] = useState(0);
  const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);
  const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isAdjudicated, setIsAdjudicated] = useState(false);
  const paginationAttributes = {
    currentPage,
    maxPageLimit,
    minPageLimit,
    response: allProjects,
  };
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const onPrevClick = () => {
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageLimit(maxPageLimit - pageNumberLimit);
      setMinPageLimit(minPageLimit - pageNumberLimit);
    }
    setCurrentPage((prev) => prev - 1);
  };

  const onNextClick = () => {
    if (currentPage + 1 > maxPageLimit) {
      setMaxPageLimit(maxPageLimit + pageNumberLimit);
      setMinPageLimit(minPageLimit + pageNumberLimit);
    }
    setCurrentPage((prev) => prev + 1);
  };

  useSuscriptionModalTimeout();

  const handleResize = () => {
    const windowWidth = window.innerWidth;
    setOcultarEnMobile(windowWidth < 768);
    setMobileStylesFromParent(windowWidth < 768);
  };

  // Ejecutar handleResize al cargar el componente
  useEffect(() => {
    handleResize();
  }, []);

  // Agregar un event listener para el cambio de tamaño de la ventana
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Limpiar el event listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <main className={styles.container}>
      {ocultarEnMobile &&
        <Botonera mobileStylesFromParent={mobileStylesFromParent} />}
      <div className={styles.projectsContainer}>
        <FilterPublicProject
          setIsFiltered={setIsFiltered}
          setIsAdjudicated={setIsAdjudicated}
          page={currentPage}
        />
        <div className={styles.AllProjects} id="allProjects">
          {loading ? (
            <Loading noModal />
          ) : (
            <div className={styles.generalContainer}>
              {allProjects && allProjects.length === 0 ? (
                <div className={styles.emptyState}>
                  <EmptyState title="No hay resultados para su búsqueda." />
                </div>
              ) : null}
              <Pagination
                {...paginationAttributes}
                onPrevClick={onPrevClick}
                onNextClick={onNextClick}
                onPageChange={onPageChange}
                isPublicProject={true}
              />
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

const mapStateToProps = (state) => {

  return {
    profileMenu: state.profile.menuShow,
    allProjects: state.dashboard.viewProjects,
    isFilter: state.dashboard.isFilter,
    page: state.dashboard.page,
    searchParams: state.dashboard.searchParams,
    loading: state.dashboard.loading,
    isLoaded: state.general.isLoaded,
    isVisualizerOpen: state.general.recoverOpen,
  };
};

export default connect(mapStateToProps, {
  clearProjects,
  setProjectFormStyle,
  setSubProjectStyle,
  resetProjectToEdit,
  filterPublicProjects,
  openFeedTextModal,
  openTutorialModal,
})(LicitacionesPublicas);
