import React, { useState, useEffect } from "react";
import styles from "./SolicitudesItem.module.scss";
import { connect, useDispatch } from "react-redux";
import {
  openMainModal,
  validateAccessRequest,
  setViewNotification,
  deleteNotification,
} from "../../../store/actions";
import CheckedProfile from "../../../assets/images/perfilChecked.png";
import { ReactComponent as SolicitudNew } from "../../../assets/svg/solicitudesNews.svg";
import { ReactComponent as Declined } from "../../../assets/svg/declinedSolicitud.svg";
import { ReactComponent as RepSVGBlue } from "../../../assets/svg/reputation-comments-blue.svg";
import { ReactComponent as AcceptedTitle } from "../../../assets/svg/check-verde.svg";
import { ReactComponent as RepSVG } from "../../../assets/svg/reputation-comments.svg";
import { ReactComponent as ViewProfile } from "../../../assets/svg/viewpassword.svg";
import { ReactComponent as TrashIcon } from "../../../assets/svg/trashIcon.svg";
import { ReactComponent as Check } from "../../../assets/svg/checkmark.svg";
import { ReactComponent as Close } from "../../../assets/svg/xClose.svg";
import LoadingDots from "../../UI/Loading/LoadingDots";
import { fireAnalytics } from "../../../utils/firebase";
import { useNavigate } from "react-router-dom";
import usePremiumCheck from "../../../hooks/usePremiumCheck";

const SolicitudesItem = ({
  userWithFreeTrialActive,
  userWithPlanActive,
  suscriptionCancelledButActive,
  isUserPlanPremium,
  deleteNotification,
  ...props
}) => {
  const dispatch = useDispatch();
  const premiumCheck = usePremiumCheck();
  const {
    id,
    postulacionPermiso,
    userSender,
    type,
    view,
    createdAt,
    userReciever,
  } = props.item;
  const solicitudType = type;
 const navigate = useNavigate();
  const [isUserPremium, setIsUserPremium] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isViewed, setIsViewed] = useState(false);
  const nombreCompleto = userSender.type === "COMPANY" ? userSender.razon_social : userSender.name + " " + userSender.last_name;
  const status = postulacionPermiso?.status;

  //Maneja el tipo de usuario
  useEffect(() => {
    if (userWithFreeTrialActive?.status === "in-course" ||
      userWithPlanActive?.status === "active" ||
      userWithPlanActive?.status === "pending" ||
      suscriptionCancelledButActive === true ||
      isUserPlanPremium === true) {
      setIsUserPremium(true);
    }
  }, [userWithFreeTrialActive, userWithPlanActive, suscriptionCancelledButActive, isUserPlanPremium]);



  const handleClickProfile = () => {
    const userName = userSender.type === "COMPANY" ? userSender.razon_social : userSender.name + " " + userSender.last_name;
    navigate(`/comunidad-edify/${userSender.id}/${userName}`);
  };

  const handleClickProyecto = () => {
    navigate(`/proyectosprivados/${postulacionPermiso.proyecto.id}`);
  };

  //Marca como vista la notificación
  const handleViewClick = () => {
    setIsLoading(true);
    dispatch(setViewNotification(id));
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  };

  //Maneja los botones de aceptar y rechazar

  const handleButtonClick = (tipo, solicitudType) => {
    if (tipo === "accept") {
      fireAnalytics("nda_approved", { category: "linking" });
      dispatch(
        openMainModal({
          title: "¿Estas seguro que deseas aceptar la solicitud?",
          body: `Si aceptas el NDA el usuario ${nombreCompleto} podrá acceder a la documentación del proyecto y enviarte presupuestos para ayudar a completar el mismo.`,
          isSuccess: false,
          confirmHandler: () => {
            dispatch(setViewNotification(parseInt(id)));
            dispatch(
            validateAccessRequest(parseInt(postulacionPermiso?.id), "ALLOWED", solicitudType));
          },
        })
      );
    } else if (tipo === "reject") {
      fireAnalytics("nda_rejected", { category: "linking" });
      dispatch(
        openMainModal({
          title: "¿Estas seguro que deseas rechazar la solicitud?",
          body: `Si rechazas el NDA no podrás recibir mas propuestas de ${nombreCompleto} para este proyecto.`,
          isSuccess: false,
          confirmHandler: () => {
            dispatch(
            setViewNotification(parseInt(id)));
            dispatch(validateAccessRequest(parseInt(postulacionPermiso?.id), "NEGATED", solicitudType));
          },
        })
      );
    }
  };

  //Elimina a notificacion
  const handleDeleteNotification = () => {
    deleteNotification(parseInt(id));
  };


  const formatTimeFromNow = (time) => {
    const date = new Date(time);
    const now = new Date();
    const diff = now - date;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (seconds < 60) {
      return `${seconds === 1 ? '1 segundo' : `${seconds} segundos`}`;
    } else if (minutes < 60) {
      return `${minutes === 1 ? '1 minuto' : `${minutes} minutos`}`;
    } else if (hours < 24) {
      return `${hours === 1 ? '1 hora' : `${hours} horas`}`;
    } else if (days < 30) {
      return `${days === 1 ? '1 día' : `${days} días`}`;
    } else if (months < 12) {
      return `${months === 1 ? '1 mes' : `${months} meses`}`;
    } else {
      return `${years === 1 ? '1 año' : `${years} años`}`;
    }
  }


  return (
    <>
      <div className={styles.item}>
        <div className={styles.topContainer}>
          <div className={styles.nameContainer}>
            {solicitudType === "POSTULACION_SEND" ? (
              <>
                {postulacionPermiso?.status === "SEND" && (
                  <div className={styles.notificationContainer_headerProject}>
                    <SolicitudNew />
                    <h4 className={styles.newRequest}>
                      Nueva solicitud de acceso a tu proyecto&nbsp;
                      <p onClick={() => handleClickProyecto()}>
                        {postulacionPermiso?.proyecto?.name}
                      </p>
                    </h4>
                  </div>)}
                  {postulacionPermiso?.status === "NEGATED" && (
                  <div className={styles.notificationContainer_header}>
                    <Declined width={15} height={15} />
                    <h4 className={styles.newRequest}>
                      Has rechazado la postulación en tu proyecto.
                    </h4>
                  </div>
                )}
                {postulacionPermiso?.status === "ALLOWED" && (
                  <div className={styles.notificationContainer_header}>
                    <AcceptedTitle width={15} height={15}/>
                    <h4 className={styles.newRequest}>
                      Has aceptado la postulación en tu proyecto.
                    </h4>
                  </div>
                )}
                <div className={styles.buttonsContainer}>
                  {view === null && !isLoading && (
                    <div className={styles.buttonsContainer_solicitudViewContainer} onClick={() => handleViewClick()} title="Marcar como vista">
                    <div className={styles.buttonsContainer_solicitudView}></div>
                    </div>
                  )}
                  {isLoading &&
                    <LoadingDots blueDots />}
                  {status === "SEND" && (
                    <div className={styles.buttonsContainer_acceptReject}>
                      <div
                        className={styles.buttonsContainer_reject}
                        onClick={() => handleButtonClick("reject", "POSTULACION_NEGATED")}
                        title={"Rechazar solicitud"}
                      >
                        <Close width={10} height={15} />
                      </div>
                      <div
                        className={styles.buttonsContainer_accept}
                        onClick={() => handleButtonClick("accept", "POSTULACION_ACCEPTED")}
                        title={"Aceptar solicitud"}
                      >
                        <Check width={10} height={15} />
                      </div>
                    </div>
                  )}
                  <div className={styles.buttonsContainer_delete} onClick={() => handleDeleteNotification()} title="Eliminar notificación">
                    <TrashIcon width={20} height={20} />
                  </div>
                </div>
              </>
            ) : solicitudType === "POSTULACION_ACCEPTED" ? (
              <>
              <div className={styles.notificationContainer_header}>
                <AcceptedTitle width={15} height={15}/>
                <h4 className={styles.newRequest}>
                  Respuesta a tu solicitud en{" "}
                  {postulacionPermiso?.proyecto?.name}
                </h4>
                </div>
                <div className={styles.buttonsContainer}>
                  {view === null && !isLoading && (
                    <div
                      onClick={() => handleViewClick()}
                      className={styles.solicitudView}
                      title="Marcar como vista"
                    >
                    </div>
                  )}
                  {isLoading &&
                    <LoadingDots blueDots />}
                  <div className={styles.buttonsContainer_delete} onClick={() => handleDeleteNotification()} title="Eliminar notificación">
                    <TrashIcon width={20} height={20} />
                  </div>
                </div>
              </>
            ) : solicitudType === "COMMENT_ENVIADO" ? (
              <>
                <RepSVGBlue />
                <h4 className={styles.newRequest}>
                  Nuevo comentario en el proyecto{" "}
                  {postulacionPermiso?.proyecto?.name}
                </h4>
              </>
            ) : solicitudType === "COMMENT_RESPONDIDO" ? (
              <>
                <RepSVG />
                <h4 className={styles.newRequest}>
                  Respuesta a tu comentario en el proyecto{" "}
                  {postulacionPermiso?.proyecto?.name}
                </h4>
              </>
            ) : solicitudType === "POSTULACION_NEGATED" ? (
              <>
                <div className={styles.notificationContainer_header}>
                  <Declined height={15} width={15} />
                  <h4 className={styles.newRequest}>
                    Respuesta a tu postulación en el proyecto &nbsp;
                    <p onClick={() => handleClickProyecto()}>
                      {postulacionPermiso?.proyecto?.name}
                    </p>
                  </h4>
                </div>
                <div className={styles.buttonsContainer}>
                  {view === null && !isLoading && (
                    <div
                      onClick={() => handleViewClick()}
                      className={styles.solicitudView}
                      title="Marcar como vista"
                    >
                    </div>
                  )}
                  {isLoading &&
                    <LoadingDots blueDots />}
                  <div className={styles.buttonsContainer_delete} onClick={() => handleDeleteNotification()} title="Eliminar notificación">
                    <TrashIcon width={20} height={20} />
                  </div>
                </div>
              </>
            ) : solicitudType === "NEW_PROFILE_VIEW" ? (
              <>
                <div className={styles.notificationContainer_header}>
                  <ViewProfile />
                  <h4 className={styles.newRequest}>Nueva visita a tu perfil</h4>
                </div>
                <div className={styles.buttonsContainer}>
                  {view === null && !isLoading && (
                    <div
                      onClick={() => handleViewClick()}
                      className={styles.solicitudView}
                      title="Marcar como vista"
                    >
                    </div>
                  )}
                  {isLoading &&
                    <LoadingDots blueDots />}
                  <div className={styles.buttonsContainer_delete} onClick={() => handleDeleteNotification()} title="Eliminar notificación">
                    <TrashIcon width={20} height={20} />
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
        {solicitudType === "POSTULACION_SEND" ? (
          <>
            <div className={styles.notificationContainer}>
              <div
                className={styles.notificationContainer_profile}
                onClick={() => handleClickProfile()}
              >
                <img
                  className={styles.notificationContainer_image}
                  src={userSender?.image_profile}
                  alt="user"
                />
                {userSender?.validated &&
                  <img className={styles.notificationContainer_checked} src={CheckedProfile} alt="checked" />
                }
              </div>
              {postulacionPermiso?.status === "NEGATED" && (
                <div className={styles.notificationContainer_txtContainer}>
                  <div className={styles.notificationContainer_txt}>
                    No podrá acceder a la documentación de tu proyecto &nbsp;
                    <span onClick={() => handleClickProyecto()}>
                      {postulacionPermiso?.proyecto?.name}
                    </span>
                  </div>
                </div>)}
              {postulacionPermiso?.status === "SEND" && (
                <div className={styles.notificationContainer_txtContainer}>
                  <p className={styles.notificationContainer_txt}>
                    Te ha enviado una solicitud para descargar las especificaciónes
                    técnicas en el proyecto
                    <span
                      className={styles.projectNameStyle}
                      onClick={() => handleClickProyecto()}
                    >
                      {postulacionPermiso?.proyecto?.name}
                    </span>
                  </p>
                </div>
              )}
              {postulacionPermiso?.status === "ALLOWED" && (
                <div className={styles.notificationContainer_txtContainer}>
                  <p className={styles.notificationContainer_txt}>
                    Aceptaste la solicitud para que {nombreCompleto} descargue las especificaciónes
                    técnicas en el proyecto
                    <span
                      className={styles.projectNameStyle}
                      onClick={() => handleClickProyecto()}
                    >
                      {postulacionPermiso?.proyecto?.name}
                    </span>
                    &nbsp;y pueda enviarte presupuestos.
                  </p>
                </div>
              )}
            </div>
            <div className={styles.notificationContainer_time}>
              Hace &nbsp;{formatTimeFromNow(createdAt)}
            </div>
          </>
        ) : solicitudType === "POSTULACION_ACCEPTED" ? (
          <>
            <div className={styles.notificationContainer}>
              <div
                className={styles.notificationContainer_profile}
                onClick={() => handleClickProfile()}
              >
                <img
                  className={styles.notificationContainer_image}
                  src={userSender?.image_profile}
                  alt="user"
                />
                {userSender?.validated &&
                  <img className={styles.notificationContainer_checked} src={CheckedProfile} alt="checked" />
                }
              </div>
              <div className={styles.notificationContainer_txtContainer}>
                <p className={styles.notificationContainer_txt}>
                  {nombreCompleto} ha aceptado tu solicitud para descargar las especificaciónes
                  técnicas en el proyecto
                  <span
                    className={styles.projectNameStyle}
                    onClick={() => handleClickProyecto()}
                  >
                    {postulacionPermiso?.proyecto?.name}
                  </span>
                </p>
              </div>
            </div>
            <div className={styles.notificationContainer_time}>
              Hace &nbsp;{formatTimeFromNow(createdAt)}
            </div>
          </>
        ) : solicitudType === "POSTULACION_NEGATED" ? (
          <>
            <div className={styles.notificationContainer}>
              <div
                className={styles.notificationContainer_profile}
                onClick={() => handleClickProfile()}
              >
                <img
                  className={styles.notificationContainer_image}
                  src={userSender?.image_profile}
                  alt="user"
                />
                {userSender?.validated &&
                  <img className={styles.notificationContainer_checked} src={CheckedProfile} alt="checked" />
                }
              </div>
              <div className={styles.notificationContainer_txtContainer}>
                <div className={styles.notificationContainer_txt}>
                  {nombreCompleto} ha rechazado tu solicitud para descargar las especificaciónes
                  técnicas en el proyecto
                  <span
                    className={styles.projectNameStyle}
                    onClick={() => handleClickProyecto()}
                  >
                    {postulacionPermiso?.proyecto?.name}
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.notificationContainer_time}>
              Hace &nbsp;{formatTimeFromNow(createdAt)}
            </div>
          </>
        ) : solicitudType === "NEW_PROFILE_VIEW" ? (
          <>
            {isUserPremium ? (
              <>
                <div className={styles.notificationContainer}>
                  <div className={styles.notificationContainer_profile} onClick={() => handleClickProfile()}>
                    <img
                      className={styles.notificationContainer_image}
                      src={userSender?.image_profile}
                      alt="user"
                    />
                    {userSender?.validated &&
                      <img className={styles.notificationContainer_checked} src={CheckedProfile} alt="checked" />
                    }
                  </div>
                  <div className={styles.notificationContainer_txtContainer}>
                    <div className={styles.notificationContainer_info}>
                      <div className={styles.userName}>{nombreCompleto}
                        &nbsp;ha visitado tu perfil.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.notificationContainer_time}>
                  Hace &nbsp;{formatTimeFromNow(createdAt)}
                </div>
              </>
            ) : (
              <div className={styles.notificationContainer}>
                <div
                  className={styles.notificationContainer_profile}
                  onClick={premiumCheck(handleClickProfile)}
                >
                  <img
                    className={styles.notificationContainer_image_blur}
                    src={userSender?.image_profile}
                    alt="user"
                  />
                  {userSender?.validated &&
                    <img className={styles.notificationContainer_checked} src={CheckedProfile} alt="checked" />
                  }
                  <div className={styles.notificationContainer_info}>
                    <span
                      className={`${styles.userName} ${styles.notificationContainer_blurredText}`}
                    >
                      {nombreCompleto}
                    </span>
                  </div>
                </div>
                <div className={styles.notificationContainer_txtContainer}>
                  <p className={styles.notificationContainer_txt}>
                    ¿Querés saber quién vio tu perfil?
                    <span
                      className={styles.notificationContainer_notPremium}
                      onClick={premiumCheck()}
                    >
                      {" "}
                      ¡Activa tu plan Premium!{" "}
                    </span>
                  </p>
                </div>
              </div>
            )}
          </>
        ) : solicitudType === "COMMENT_ENVIADO" ? (
          <div className={styles.sendSolicitud}>
            <span
              className={styles.userName}
              onClick={() => handleClickProfile()}
            >
              {userSender.name}
            </span>
            ha enviado un comentario en el proyecto
            <span
              className={styles.projectNameStyle}
              onClick={() => handleClickProyecto()}
            >
              {postulacionPermiso?.proyecto?.name}
            </span>
          </div>
        ) : solicitudType === "COMMENT_RESPONDIDO" ? (
          <div className={styles.sendSolicitud}>
            <span
              className={styles.userName}
              onClick={() => handleClickProfile()}
            >
              {userSender.name}
            </span>
            ha respondido a tu comentario en el proyecto
            <span
              className={styles.projectNameStyle}
              onClick={() => handleClickProyecto()}
            >
              {postulacionPermiso?.proyecto?.name}
            </span>
          </div>
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    dashboardView: ownProps.dashboardView || false,
    item: ownProps.item,
    isSettingPostulation: state.dashboard.isSettingPostulation,
    profileVisualizer: state.general.profileVisualizer,
    userWithFreeTrialActive: state.general.userData.free_trial,
    userWithPlanActive: state.general.userData?.subscription,
    suscriptionCancelledButActive: state.general.isCancelledSuscriptionStillActive,
    isUserPlanPremium: state.general.isUserPremium,
  };
};

export default connect(mapStateToProps, {
  openMainModal,
  validateAccessRequest,
  setViewNotification,
  deleteNotification,
})(SolicitudesItem);
