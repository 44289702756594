import React, { useEffect } from "react";
import styled from "styled-components";
import { 
  setProjectFormStyle,
  setSubProjectStyle,
  resetProjectToEdit,
  generateVisit,
  setGoBackToCategoryList,
  getProfileVisualizer,
} from "../../store/actions";
import PerfilMain from "../../components/Perfil/PerfilMain/PerfilMain";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;

const RegisteredUserProfile = ({
  profileMenu,
  setProjectFormStyle,
  setSubProjectStyle,
  resetProjectToEdit,
  generateVisit,
  getProfileVisualizer,
  setGoBackToCategoryList,
  profileVisualizerData,
  userData,
   ...props }) => {
const location = useLocation();
 const navigate = useNavigate();

  //Obtenemos el id de la url
  const id = location.pathname.split("/")[2];
  
  //Lo comparamos para saber si es perfil propio o ajeno
  const userId = id === userData.id ? userData.id : id;
  
  //Chequeamos si es perfil propio o ajeno
  const myPerson = userId === userData.id
  window.scrollTo({ top: 0, behavior: "smooth" });

  //Genera la visita en el perfil
  useEffect(() => {
    if (!myPerson) {
      generateVisit(parseInt(userId));
    }
  }, []);

useEffect(() => {
  if (!myPerson) {
    getProfileVisualizer(userId);
  }
}, [userId]);
   
  return (
      <Container>             
          <PerfilMain perfilAjeno={userId} profileVisualizerData={profileVisualizerData} myPerson={myPerson} />
      </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    profileMenu: state.profile.menuShow,
    userData: state.general.userData,
    profileVisualizerData: state.general.profileVisualizer,
  };
};

export default connect(mapStateToProps, { 
  setProjectFormStyle,
  setSubProjectStyle,
  resetProjectToEdit,
  generateVisit,
  setGoBackToCategoryList,
  getProfileVisualizer,
 })(
  RegisteredUserProfile
);
