import React, { useState, useEffect } from "react";
import styles from "./marketplace.module.scss";
import styled from "styled-components";
import CalculadoraDeMateriales from "./CalculadoraDeMateriales/CalculadoraDeMateriales";
import { ReactComponent as Faq } from "../../assets/svg/FAQ.svg";
import FondoCalculadora from "../../assets/images/fondoCalculadora.jpg";
import FondoListado from "../../assets/images/fondoListado.jpg";
import CommingSoonBanner from "../Dashboard/Banner/CommingSoonBanner";
import Botonera from "../Botonera/Botonera";
import { motion } from "framer-motion";
import { connect } from "react-redux";
import {
  getDashboardProjects,
  getProjects,
  resetFilterBy,
  setProjectFormStyle,
  setSubProjectStyle,
  resetProjectToEdit,
  openTutorialModal,
  openFeedTextModal,
} from "../../store/actions";
import QuotationsList from "./QuotationsList";

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 85vh;
  `;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;

  &.isVisualizerOpen {
    max-height: 1500px;
    overflow: hidden;
  }
  @media (max-width: 900px) {
    width: 100%;
    max-width: auto;
    min-width: auto;
  }
`;

const Quotations = ({
  allProjects,
  openTutorialModal,
  tutorialModal,
}) => {
  const [showCalculator, setShowCalculator] = useState(false);
  const [showQuotationsList, setShowQuotationsList] = useState(false);
  const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
  const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);

  const handleResize = () => {
    const windowWidth = window.innerWidth;
    setOcultarEnMobile(windowWidth < 768);
    setMobileStylesFromParent(windowWidth < 768);
  };

  // Ejecutar handleResize al cargar el componente
  useEffect(() => {
    handleResize();
  }, []);

  // Agregar un event listener para el cambio de tamaño de la ventana
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Limpiar el event listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const showCalculatorComponent = () => {
    setShowCalculator(true);
    setShowQuotationsList(false);
  };

  const showQuotationsListComponent = () => {
    setShowCalculator(false);
    setShowQuotationsList(true);
  };

  const showOptionsComponent = () => {
    setShowCalculator(false);
    setShowQuotationsList(false);
  };

  const handleOnClickMail = () => {
    window.location.href = "mailto: gonzalo@edify.la subject=Quiero%20publicitar%20mis%20productos%20en%20edify";
  };


  return (
    <Container>
          {ocultarEnMobile && <Botonera mobileStylesFromParent={mobileStylesFromParent}/>}
      <Wrapper>
        {!showCalculator && !showQuotationsList && (
          <div className={styles.generalContainer}>
          <div className={styles.container}>         
          <motion.div className={styles.container_section} initial={{ x: -500 }} animate={{ x: 0 }} transition={{ duration: 0.5 }}>
              <div className={styles.container_section_backdropList}>
                <div className={styles.container_section_button} onClick={showQuotationsListComponent}>Ver los pedidos de cotización de materiales</div>
                <img src={FondoListado} alt="FondoListado" className={styles.container_background} />
              </div>
              <div className={styles.container_section_description}>
              <div className={styles.container_section_title}>Revisa los pedidos de cotización de materiales existentes</div>
                <ul className={styles.container_section_description_txt}>
                  <li>Usá el filtrador para encontrar los pedidos de cotización públicos o privados.</li>
                  <li>Accedé a los pliegos de los pedidos de cotización de entidades públicas.</li>
                  <li>Para presupuestar en pedidos privados de usuarios de edify, decargá la planilla con los requerimientos desde la publicación y enviá tu cotización.</li>
                  <li>Podés ver todos los detalles de cada pedido de cotización haciendo click en "ver más".</li>
                </ul>
              </div>
            </motion.div>
            <motion.div className={styles.container_section} initial={{ x: 500 }} animate={{ x: 0 }} transition={{ duration: 0.5 }}>
              <div className={styles.container_section_description}>
              <div className={styles.container_section_title}>Obtené una estimación de costos de materiales por parte de edify</div>
                <ul className={styles.container_section_description_txt}>
                  <li>Podés armar tu pedido consultando nuestra base de datos de materiales.</li>
                  <li>Relevamos más de 1000 productos de diferentes proveedores.</li>
                  <li>Al finalizar, vas a poder descargar tu estimación de costos e imprimirla.</li>
                  <li>Si querés una cotización real por parte de la comunidad de edify, podés publicar tu pedido de cotización.</li>
                </ul>
              </div>
              <div className={styles.container_section_backdropCalculator}>
                <div className={styles.container_section_buttonCalculator} onClick={showCalculatorComponent}>Usar la calculadora de costos de materiales de edify</div>
                <img src={FondoCalculadora} alt="FondoCalculadora" className={styles.container_backgroundCalculator} />
              </div>
            </motion.div>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5, delay: 0.5 }} className={styles.container_section_disclaimer}>
                  <p>*Los precios que se muestran son estimativos y pueden variar. Edify no se responsabiliza por la precisión de los precios mostrados.</p>
                  <p className={styles.container_section_disclaimer_mail} onClick={() => handleOnClickMail()}>*¿Sos proveedor de materiales? <b>Escríbenos</b> para publicitar tus productos</p>
                </motion.div>
          </div>   
          </div>       
        )}

        {showCalculator && (
          <div className={styles.containerCalculator}>
          <div className={styles.containerCalculator_buttonsContainer}>
            <button className={styles.containerCalculator_button} onClick={showOptionsComponent}>Volver a la selección de opciones</button>
            <button className={styles.containerCalculator_button} onClick={showQuotationsListComponent}>Mostrar Lista de Cotizaciones</button>
            </div>
           {/*  <CalculadoraDeMateriales /> */}
            <CommingSoonBanner />         
          </div>
        )}

        {showQuotationsList && (
          <div className={styles.containerList}>
          <div className={styles.containerList_buttonsContainer}>
            <button className={styles.containerCalculator_button} onClick={showOptionsComponent}>Volver</button>
            <button className={styles.containerCalculator_button} onClick={showCalculatorComponent}>Ir a la calculadora</button>
            </div>
            <QuotationsList item={allProjects.entities} />         
          </div>
        )}
      </Wrapper>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  allProjects: state.dashboard.viewProjects,
  showButton: state.dashboard.allProjects,
  proyectos: state.profile.proyectos,
  postulaciones: state.profile.postulations,
  isLoaded: state.general.isLoaded,
  isVisualizerOpen: state.general.recoverOpen,
  profileMenu: state.profile.menuShow,
  tutorialModal: state.dashboard.tutorialModal,
});

export default connect(mapStateToProps, {
  getDashboardProjects,
  getProjects,
  resetFilterBy,
  setProjectFormStyle,
  setSubProjectStyle,
  resetProjectToEdit,
  openFeedTextModal,
  openTutorialModal,
})(Quotations);
