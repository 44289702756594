import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { connect } from "react-redux";
import styles from "./NewProject.module.scss";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import successData from "../../assets/lotties/27-loading.json";
import errorData from "../../assets/lotties/errorLogo.json";
import {
  deleteProyecto,
  openMainModal,
  closeMainModal,
  resetSubProjects,
  resetIdProject,
  updateProyecto,
  getProjectsByType,
  resetProjectToEdit,
  openEdifySpinner,
  createNewProject,
} from "../../store/actions";
import { ReactComponent as Pencil } from "../../assets/svg/pencil-new.svg";
import { ReactComponent as Publicar } from "../../assets/svg/publicarProyecto.svg";
import { ReactComponent as CloseIcon } from "../../assets/svg/xClose.svg";
import useFileUpload from "../../hooks/useFileUpload";
import DetailsForm from "../UI/DetailsForm/DetailsForm";
import Subproyectos from "../Proyectos/Subproyectos/Subproyectos";
import { motion } from "framer-motion";
import { fireAnalytics } from "../../utils/firebase";
const variants = {
  visible: {
    scale: 1.0,
  },
  hidden: {
    scale: 1.1,
  },
};
const defaultOptions = {
  loop: false,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};



const NewProject = ({
  userData,
  openMainModal,
  isEditing,
  postulationProject = {},
  updateProyecto,
  deleteProyecto,
  closeModal,
  step1Project,
  subProjectsToEdit,
  createNewProject,
  closeMainModal,
  openEdifySpinner,
  resetSubProjects,
  subProjects,
  ...props
}) => {
  const [submitLoading, setSubmitLoading] = useState(false);
 const navigate = useNavigate();
  const [zone, setZone] = useState(isEditing ? postulationProject.zone : "");
  const [image, setImage] = useState(isEditing ? postulationProject.proyecto_images[0]?.image : "");
  const [changedZone, setChangeZone] = useState(false);
  const [minDate, setMinDate] = useState('');
  const [minDateForSubProjects, setMinDateForSubProjects] = useState('');
  const [maxDateForSubProjects, setMaxDateForSubProjects] = useState('');
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isUpdateButtonActive, setIsUpdateButtonActive] = useState(false);
  const [hidenForm, setHidenForm] = useState(false);
  const [finalStep, setFinalStep] = useState(false);
  const [imageEdit, setImageEdit] = useState([]);
  const [changedImages, setChangedImages] = useState(false);
  const [hasSubprojects, setHasSubprojects] = useState(false);
  const [imageFile, imageChangeHandler, fileName] = useFileUpload(
    image,
    setImage
  );

  const ProyectoSchema = Yup.object().shape({
  name: Yup.string().required("El campo es requerido"),
  description: Yup.string().required("El campo es requerido"),
  endDate: Yup.date().required("El campo es requerido"),
  startDate: Yup.date().required("El campo es requerido"),
  zone: Yup.string().required("El campo es requerido"),
});

  let imageFileEdit = []

  const initialValues = {
    name: isEditing ? postulationProject.name : "",
    description: isEditing ? postulationProject.description : "",
    endDate: isEditing ? postulationProject.endDate : "",
    startDate: isEditing ? postulationProject.startDate : "",
    zone: isEditing ? postulationProject.zone : "",
    image: isEditing ? postulationProject.proyecto_images[0]?.image : "",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: ProyectoSchema,
    enableReinitialize: true,
    validateOnMount: true,
  });

  
  //Array de imagenes existentes en el proyecto
  const imagesFromProject = postulationProject?.proyecto_images ? postulationProject?.proyecto_images : [];

  useEffect(() => {
    if (subProjects.length > 0) {
      setHasSubprojects(true);
    } else if (isEditing && postulationProject.subproyecto.length > 0) {
      setHasSubprojects(true);
    } else {
      setHasSubprojects(false);
    }
  }, [subProjects]);

  // Maneja el texto del botón de publicar proyecto paso 1
  let contentPaso = formik.isValid && hasSubprojects ? "Publicar" : "Completá los campos";

  useEffect(() => {
    resetProjectToEdit();
    resetSubProjects();
    resetIdProject();
  }, []);

  const divToScrollRef = useRef(null);

  //Nueva funcion para crear un proyecto 
  const sendNewProject = () => { 
    openEdifySpinner(true);
    const newProject = {
      ...formik.values,
      image: imageEdit,
    };
    if (
      formik.values.description !== "" &&
      formik.values.endDate !== "" &&
      formik.values.name !== "" &&
      formik.values.zone !== "" &&
      formik.values.startDate !== ""
    ) {
      fireAnalytics("publish_project", { category: "publishing" });
      createNewProject(newProject, subProjects, navigate);
      closeModal();
    }
  }

  //Funcion para manejar el cerrado del modal
  const handleCloseModal = () => {
    openMainModal({
      title: "¿Deseas salir de la creación del proyecto?",
      body: "Al cerrar la ventana, perderás los cambios realizados.",
      isSuccess: false,
      confirmHandler: () => {
        closeModal();
        closeMainModal();
      },
    });
  };
  

  // Botón de eliminar proyecto 
  const deleteHandler = () => {
    setHidenForm(true);
    const projectId = postulationProject.id;
    fireAnalytics("draft_project", { category: "publishing" });
    openMainModal({
      title: "¿Deseas eliminar el proyecto?",
      body: "Si elimina el proyecto, nadie podra postularse.",
      isSuccess: false,
      confirmHandler: () => {
        deleteProyecto(projectId);
      },
      customCloseMainModal: () => {
        setHidenForm(false);
        closeMainModal();
      },
    });
    closeModal && (closeModal());
  };

  // Función para editar el proyecto en el paso 2 de 2

  const updateProject = () => {
    const updateProject = {
      ...formik.values,
      startDate: postulationProject.startDate,
      link: postulationProject.link,
      zone: formik.values.zone,
    };
    let imageToSend = imageEdit
    if (
      formik.values.description === "" &&
      formik.values.endDate === "" &&
      formik.values.name === "" &&
      formik.values.zone === ""
    ) {
      openMainModal({
        title: "¡Atención!",
        body:
          formik.values.description === "" &&
            formik.values.endDate === "" &&
            formik.values.name === ""
            ? "Los campos de nombre, descripción y fecha de finalización no pueden estar vacíos"
            : zone === postulationProject.zone && changedZone
              ? "Debes seleccionar una ubicación diferente a la actual"
              : "Lamentablemente no hemos podido procesar su solicitud de actualización ya que no se han detectado cambios en el proyecto",
        isSuccess: true,
        confirmHandler: null,
      });
    } else {
      openMainModal({
        title: "¿Deseas guardar los cambios efectuados?",
        body: "Si tenías usuarios postulados en el proyecto, se le notificarán de los cambios realizados. ",
        isSuccess: false,
        confirmHandler: () => {
          updateProyecto(updateProject, imageToSend, postulationProject.id);
        },
      });
    }
  };

  //Setea la imagen a enviar
  const handleImageFilesChange = (file) => {
    setImageEdit(file);
  };

  //obtenemos fecha mínima para publicar el proyecto
  useEffect(() => {
    const hoy = new Date();
    let dd = hoy.getDate();
    let mm = hoy.getMonth() + 1;
    let yyyy = hoy.getFullYear();
    const minDate = `${yyyy}-${mm < 10 ? `0${mm}` : mm}-${dd < 10 ? `0${dd}` : dd}`;
    setMinDate(minDate);
  }, []);

  return (
    <>
      {isEditing ? (
        <motion.div
          className={styles.newProjectEdit}
          initial="hidden"
          animate="visible"
          variants={variants}
          sizeTransition
        >
          <header className={styles.newProject_header}>
            <div className={styles.newProject_header_txtContainer}>
              <Pencil  width={15} height={15}/>
              <div>Editor de proyecto</div>
            </div>
            <div className={styles.close} onClick={() => closeModal()}>
              <span className={styles.cancel}>
                <CloseIcon  width={15} height={15} />
              </span>
            </div>
          </header>
          <main className={styles.newProject_container}>
              <div className={styles.newProject_containerEdit}>
                <section className={styles.newProject_containerSection}>
                  <DetailsForm
                    setChangeZone={setChangeZone}
                    values={formik.values}
                    imageFile={imageFile}
                    imageChangeHandler={imageChangeHandler}
                    onImageFilesChange={handleImageFilesChange}
                    imageFileEdit={imageFileEdit}
                    formik={formik}
                    fileName={fileName}
                    zone={zone}
                    setZone={setZone}
                    minDate={minDate}
                    isEditing={isEditing}
                    imagesFromProject={imagesFromProject}
                    hidenForm={hidenForm}
                    projectId={postulationProject.id}
                    setChangedImages={setChangedImages}
                  />
                </section>
              </div>
             <section className={styles.newProject_containerSection}>
              <h4 className={styles.newProject_containerSection_title}>
                AGREGAR RUBROS A COTIZAR AL PROYECTO:
                {!hasSubprojects ? (
                  <div className={styles.error}>
                    <Lottie
                      options={{
                        animationData: errorData,
                        ...defaultOptions,
                      }}
                      height={30}
                      width={30}
                    />
                  </div>
                ) : (<div className={styles.newProject_containerSection_title_count}>
                {postulationProject?.subproyecto?.length}
              </div>)}
              </h4>
              <Subproyectos
                subprojectsEdit={postulationProject.subproyecto}
                isEditing={isEditing}
                projectIdForSub={postulationProject.id}
                closeModal={closeModal}
              />
            </section>
            <div className={styles.newProject_buttonContainer}>
                <button
                  className={`${styles.newProject_buttonDelete_button} ${styles.delete}`}
                  onClick={deleteHandler}
                >
                  Eliminar proyecto
                </button>          
              {(formik.dirty || changedImages) && <div
                className={styles.newProject_button}
                onClick={updateProject}
                disabled={submitLoading}
              >
                  <div className={styles.contentStyle}>
                    <Publicar /> Guardar cambios
                  </div>
              </div>}
            </div>
          </main>
        </motion.div>) : (
        <motion.div
          className={styles.newProject}
          initial="hidden"
          animate="visible"
          variants={variants}
          sizeTransition
          style={isEditing ? { marginTop: "2rem" } : null}
        >
          {finalStep ? null : (
            <div className={styles.bodyContainer}>
              <header className={styles.newProject_header}>
                <div className={styles.newProject_header_txtContainer}>
                  <Pencil width={15} height={15}/>
                  <div>Crear proyecto</div>
                </div>
                <div className={styles.close} onClick={() => handleCloseModal()}>
                  <span className={styles.cancel}>
                    <CloseIcon width={15} height={15}/>
                  </span>
                </div>
              </header>
              <main ref={divToScrollRef} className={ styles.newProject_container}>
                  <div className={styles.newProject_fromNewContainer}>
                    <section className={styles.newProject_containerSection}>
                      <DetailsForm
                        setChangeZone={setChangeZone}
                        values={formik.values}
                        imageFile={imageFile}
                        imageChangeHandler={imageChangeHandler}
                        formik={formik}
                        fileName={fileName}
                        minDate={minDate}
                        fromNew
                        zone={zone}
                        setZone={setZone}
                        onImageFilesChange={handleImageFilesChange}
                        imageFileEdit={imageFileEdit}
                        hidenForm={hidenForm}
                        projectId={postulationProject.id}
                        setChangedImages={setChangedImages}
                        setMaxDateForSubProjects={setMaxDateForSubProjects}
                        setMinDateForSubProjects={setMinDateForSubProjects}
                      />
                    </section>
                  <section className={styles.newProject_containerSection}>                 
                    <h4  className={styles.newProject_containerSection_title}>
                      AGREGAR RUBROS A COTIZAR AL PROYECTO:
                      {!hasSubprojects ? (
                  <div className={styles.error}>
                    <Lottie
                      options={{
                        animationData: errorData,
                        ...defaultOptions,
                      }}
                      height={30}
                      width={30}
                    />
                  </div>
                ) : (
                  <div className={styles.newProject_containerSection_title_count}>
                    {subProjects.length}
                  </div>
                )}
                    </h4>
                    <Subproyectos
                      subprojectsEdit={postulationProject.subproyecto}
                      isEditing={isEditing}
                      projectIdForSub={step1Project.id}
                      closeModal={closeModal}
                      maxDateForSubProjects={maxDateForSubProjects}
                      minDateForSubProjects={minDateForSubProjects}
                    />
                  </section> 
                <div className={`${styles.newProject_buttonContainer}`}>
                  <div
                      className={`${styles.newProject_button} ${(!formik.isValid || !hasSubprojects) && styles.disabled }`}
                      onClick={sendNewProject}>
                      <div className={styles.contentStyle}>
                        <Publicar /> {contentPaso}
                      </div>
                    </div>
                  </div>
                  <div className={styles.newProject_buttonDelete}>
                    {isFormSubmitted ? (
                      <button
                        className={styles.newProject_buttonDelete_button}
                        onClick={deleteHandler}
                      >
                        Eliminar proyecto
                      </button>
                    ) : null}
                    {isUpdateButtonActive ? <div
                      className={styles.newProject_button}
                      onClick={updateProject}
                      disabled={submitLoading}
                    >
                        <div className={styles.contentStyle}>
                          <Publicar /> Guardar cambios
                        </div>
                    </div> : null}
                  </div>
                </div>
              </main>
            </div>
          )}
        </motion.div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.general.userData,
    showSubprojects: state.profile.showSubprojects,
    sendProject: state.profile.sendProject,
    projectIdForSub: state.profile.projectIdForSub,
    step1Project: state.profile.projectStep1Id,
    submitLoading: state.profile.submitLoading,
    formStep2Style: state.profile.styleForm,
    edifySpinner: state.general.edifySpinner,
    subProjectsToEdit: state.profile.projectToEdit,
    subProjects: state.profile.sendSubprojects,
  };
};

export default connect(mapStateToProps, {
  resetIdProject,
  openMainModal,
  updateProyecto,
  deleteProyecto,
  getProjectsByType,
  createNewProject,
  closeMainModal,
  openEdifySpinner,
  resetSubProjects,
})(NewProject);
